
// @ts-nocheck
import Vue from "vue";
import { isUndefined, isNull, isEmpty, isNaN, find, isNumber } from "lodash";
import { AdvertiserList } from "../../../../interfaces/advertiser";
import DatePicker from "../../../../components/Content/DatePicker.vue";
import DataPickerStarTime from "../../../../components/Content/DataPickerStarTime.vue";
import CardTextField from "../../../../components/Content/CardTextField.vue";
import CardAutocomplete from "../../../../components/Content/CardAutocomplete.vue";
import CardSwitch from "../../../../components/Content/CardSwitch.vue";
import RadioButton from "../../../../components/Content/RadioButton.vue";
import { getError } from "../../../../utils/resolveObjectArray";
import {
	isRequired,
	isAfterCompare,
	isSameOrAfterToday,
	isAfterToday,
	isAfterCompareAndAfterToday,
	isMin,
	isMax,
	isMaxByCurrency,
	LIMIT_IMPRESIONS,
	isMaxCustomLength,
	//isMaxBySpend
	// @ts-ignore
} from "@/services/rule-services";
// @ts-ignore
import CardActions from "@/components/Content/CardAction.vue";
// @ts-ignore
import Separator from "@/components/Content/Separator.vue";
// @ts-ignore
import { mapGetters } from "vuex";
import ButtonTooltip from "@/components/Content/Commons/ButtonTooltip.vue";

const BY_CAMPAIGN = "By Campaign";
const BY_LINE = "By Line";
const DAILY = "Daily";
const ASAP = "ASAP";
const IMPRESSIONS = "Impressions";
const SPEND = "Spend";
const IMPRESSION = "Impression";
const CLICKS = "Clicks";
const COMPLETED_VIDEO = "Completed Video";

const CAMPAIGN_PMP_YES = true;
// Configs to Date
const DEFAULT_DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
// const DEFAULT_START_TIME = "00:00:00";
// const DEFAULT_END_TIME = "00:59:59";
const MAX_KPI_OBJETIVE_IMPRESSION = 5000000;

// @ts-check

export default Vue.extend({
	name: "Overview",
	props: {
		traffickers: {
			type: Array,
			default: function () {
				return [];
			},
		},
		campaign: {
			type: Object,
			default: function () {
				return { frequency_caps: [] };
			},
		},
		account: {
			type: Object,
			default: function () {
				return {};
			},
		},
		budget_types: {
			type: Array,
			required: true,
		},
		advertisers: {
			type: Array,
			required: true,
		},
		campaigns_pacing: {
			type: Array,
			required: true,
		},
		optimization_strategies: {
			type: Array,
			required: true,
		},
		kpi_campaigns: {
			type: Array,
			required: true,
		},
		unit_times: {
			type: Array,
			required: true,
		},
		start_time: {
			type: String,
			default: "00:00:00",
		},
		end_time: {
			type: String,
			default: "00:59:59",
		},
		is_edit: {
			type: Boolean,
			default: false,
		},
		errors: {
			type: Object,
			default: function () {
				return {};
			},
		},
		optimization_ID: {
			type: Number,
			default: 0,
		},
		field_label: {
			type: Object,
			default: function () {
				return {};
			},
		},
		continent:{
			type: Array,
			default: function () {
				return [];
			},
		},
		frecuency_types:{
			type: Array,
			default: function () {
				return [];
			},
		},
		campaignTemp:{
			type: Object,
			default: function () {
				return {};
			},
		}
	},
	components: {
		DatePicker,
		DataPickerStarTime,
		CardTextField,
		CardAutocomplete,
		CardSwitch,
		RadioButton,
		CardActions,
		Separator,
		ButtonTooltip
	},
	data: () => ({
		title: "OverviewCampaign",
		valid: true,
		today: new Date().toISOString().substr(0, 10),
		openStartDate: false,
		openStartTime: false,
		openEndDate: false,
		openEndTime: false,
		expected_show: false,
		expected_value: undefined,
		expected_label: "",
		campaign_duration: undefined,
		// Parámetros usados para Start y End Date
		start_date_rules: [],
		end_date_rules: [],
		is_changed_date: false,

		// Parámetros usados para KPI Objetive Rules
		KPI_objetive_rules: [],

		rules: {
			daily_budget: []
		},
		type_frecuency_caps_selected: undefined,
		showActionButtons: [] as string[],
	}),
	created() {
		this.$nextTick(async() => {
			await this.getBtnShows();
		});
	},
	mounted() {},
	computed: {

		...mapGetters("profile", ["isRolReport"]),

		isReadOnly(){
			return this.isRolReport;
		},

		getMinDate() {
			return this.moment().format(DEFAULT_DATE_TIME_FORMAT);
		},

		getMinEndDate() {
			return this.moment(this.campaign.start_date).format(
				DEFAULT_DATE_TIME_FORMAT
			);
		},

		hasID() {
			return Boolean(this.$route.params.id > 0);
		},

		hasLineItems(){
			return this.isEdit && Boolean(this.campaign.line_items_count > 0);
		},

		isEdit(): Boolean {
			return this.hasID;
		},

		isEnabledField(){
			return this.isEdit ? isNull(this.campaign.cloned_from) : this.isEdit;
		},

		/**
		 * STRING RULES
		 */

		getBudgetText() {
			if (isUndefined(this.campaign.budget_type_id)) return "Total";
			const result = find(this.getBudgetType, {
				id: this.campaign.budget_type_id,
			});
			let val = result ?
						(String(result.value).toUpperCase().includes(String(SPEND).toUpperCase())
						? "Budget" : result.value)
						: "Total";
			return result ? `Total ${val}` : "Total";
		},

		getDailyBudgetHint() {
			let isSuggested = this.calcSuggested <= this.campaign.daily_budget;
			return this.getError('daily_budget') || !isSuggested && this.$t('daily_budget_not_suggested') || ""
		},
		getBudgetHelpText() {
			if (isUndefined(this.campaign.budget_type_id))
				return this.tooltipData(this.$t('campaign.help.total'));
			const result = find(this.getBudgetType, {
				id: this.campaign.budget_type_id,
			});

			if(result){
				return String(result.value).toUpperCase().includes(String(SPEND).toUpperCase())
						? this.tooltipData(this.$t('campaign.help.total'))
						: this.tooltipData(this.$t('campaign.help.impression_total'));

			}
			return this.tooltipData(this.$t('campaign.help.total'));
		},

		getRules() {
			return {
				isRequired,
				isAfterCompare,
				isSameOrAfterToday,
				isAfterToday,
				isAfterCompareAndAfterToday,
				isMin,
				isMax,
				isMaxByCurrency,
				LIMIT_IMPRESIONS,
				isMaxCustomLength,
				//isMaxBySpend
			};
		},

		getRuleDailyBudget() {
			return [
				this.isCampaignPacingById ? this.getRules.isRequired : true,
				// Remove daily budget suggested validation
				// this.isCampaignPacingById && this.calcSuggested > 0
				// 	? this.getRules.isMin(
				// 			this.campaign.daily_budget,
				// 			this.calcSuggested
				// 	  )
				// 	: true,
			];
		},

		calcSuggested() {
			return Math.ceil(this.campaign.budget / this.campaign_duration);
		},

		getSuggested() {
			if (!this.isCampaignPacingById) return;
			if (!this.campaign.budget) return "Budget is required";
			if (!this.campaign_duration) return "Duration is required";
			return `Suggested ${this.calcSuggested}`;
		},

		getKpiObjectiveLabel() {
			let label = this.$t('campaign.fields.delivery_objective');
			this.KPI_objetive_rules = [];
			if (this.isKpiCampaignImpression) {
				this.KPI_objetive_rules = [
					this.getRules.isRequired,
					this.getRules.isMax(
						this.campaign.kpi_objective,
						MAX_KPI_OBJETIVE_IMPRESSION
					),
				];
				label = this.$t('campaign.fields.delivery_objective_impressions');
			}
			if (this.isKpiCampaignClicks) {
				label = this.$t('campaign.fields.delivery_objective_clicks');
			}
			if (this.isKpiCampaignCompletedVideo) {
				label = this.$t('campaign.fields.delivery_objective_videos');
			}

			return label;
		},

		showCampaignPacing() {
			return this.isOptimizationStrategyById;
		},

		showDailyBudget() {
			return (
				this.isOptimizationStrategyByType(BY_CAMPAIGN) &&
				this.checkCampaignPacing
			);
		},

		isBudgetTypeImpressions() {
			return this.checkSelectedIDByName(
				this.getBudgetType,
				IMPRESSIONS,
				this.campaign.budget_type_id
			);
		},

		isBudgetTypeSpend() {
			return this.checkSelectedIDByName(
				this.getBudgetType,
				SPEND,
				this.campaign.budget_type_id
			);
		},

		isKpiCampaignImpression() {
			return this.checkSelectedIDByName(
				this.getKpiCampaigns,
				IMPRESSION.toLowerCase(),
				this.campaign.kpi_campaign_id
			);
		},

		isKpiCampaignClicks() {
			return this.checkSelectedIDByName(
				this.getKpiCampaigns,
				CLICKS.toLowerCase(),
				this.campaign.kpi_campaign_id
			);
		},

		isKpiCampaignCompletedVideo() {
			return this.checkSelectedIDByName(
				this.getKpiCampaigns,
				COMPLETED_VIDEO.toLowerCase(),
				this.campaign.kpi_campaign_id
			);
		},

		isOptimizationStrategyById() {
			return this.checkSelectedIDByName(
				this.getOptimizationStrategies,
				BY_CAMPAIGN,
				this.campaign.optimization_strategy_id
			);
		},

		isOptimizationStrategyByLine() {
			return this.checkSelectedIDByName(
				this.getOptimizationStrategies,
				BY_LINE,
				this.campaign.optimization_strategy_id
			);
		},

		isCampaignPacingById() {
			return this.checkSelectedIDByName(
				this.getCampaignsPacing,
				DAILY,
				this.campaign.campaign_pacing_id
			);
		},

		isShowContinent(){
			return this.account.show_continents;
		},

		isdelivery(){
			if(this.isOptimizationStrategyById && this.isCampaignPacingById){
				return true;
			}else{
				return false;
			}
		},
		isPMPYes(){
			return this.checkPMPSelectedByID(
				this.getCampaingPmp,
				CAMPAIGN_PMP_YES,
				this.campaign.campaign_pmp
			)
		},

		isEditFieldPacing(){
			return (this.isEdit && this.isOptimizationStrategyById)
		},

		checkCampaignPacing() {
			return this.checkSelectedIDByNames(
				this.getCampaignsPacing,
				[DAILY, ASAP],
				this.campaign.campaign_pacing_id
			);
		},

		getExpectedValue() {
			return this.expected_value;
		},

		getExpectedLabel() {
			return this.expected_label;
		},

		getBudgetRules() {
			let rules: Array<any> = [this.getRules.isRequired];
			// let rule: any;
			// if (!!this.campaign.budget && !!this.campaign.budget_type_id) {
			// 	if (this.isBudgetTypeSpend) {
			// 		rule = this.getRules.isMaxByCurrency(
			// 			this.campaign.budget,
			// 			this.account.currency.key
			// 		);
			// 		rules.push(rule);
			// 	} else {
			// 		rule = this.getRules.isMax(
			// 			this.campaign.budget,
			// 			this.getRules.LIMIT_IMPRESIONS
			// 		);
			// 		rules.push(rule);
			// 	}
			// }
			return rules;
		},

		/**
		 * GET
		 */
		getCurrency() {
			if (this.isEdit) {
				return !isUndefined(this.campaign.currency_key) &&
					!isEmpty(this.campaign.currency_key)
					? `${this.campaign.currency_key}`
					: "N/A";
			} else {
				return !isUndefined(this.account.currency) &&
					!isEmpty(this.account.currency)
					? `${this.account.currency.key} (${this.account.currency.glyph})`
					: "N/A";
			}
		},

		getTimezone() {
			if (this.isEdit) {
				return !isUndefined(this.campaign.timezone) &&
					!isEmpty(this.campaign.timezone)
					? `${this.campaign.timezone.name}`
					: "N/A";
			} else {
				return !isUndefined(this.account.timezone) &&
					!isEmpty(this.account.timezone)
					? `${this.account.timezone.name}`
					: "N/A";
			}
		},

		getAdvertisers(): AdvertiserList[] {
			return this.advertisers;
		},

		getContinentes(){
            return this.continent;
        },

		getBudgetType() {
			return this.budget_types;
		},

		getCampaignsPacing() {
			if(this.isOptimizationStrategyById && this.isPMPYes){
				return this.campaigns_pacing
						.filter(element => element.value != ASAP);
			}else{
				return this.campaigns_pacing;
			}
		},

		getOptimizationStrategies() {
			return this.optimization_strategies;
		},

		getKpiCampaigns() {
			return this.kpi_campaigns;
		},

		getUnitTimes() {
			return this.unit_times;
		},

		get_suffix_currency() {
			if (this.isBudgetTypeSpend) {
				return this.getCurrency;
			}
		},

		getEstado() {
			const isByLine: Boolean = this.checkSelectedIDByName(
				this.getOptimizationStrategies,
				BY_LINE,
				this.optimization_ID
			);

			if(this.isEdit && this.isPMPYes) return true;

			return this.optimization_ID != 0 && this.isEdit && isByLine;
		},
		getCampaingPmp(){
			return [
				{ id: false, value: "No"},
				{ id: true, value: "Yes"}
			];
		},
		getErrors() {
			return this.$store.state.proccess.errors;
		},
		/*getSpendMax() {
			if (this.isBudgetTypeSpend) {
				let result = this.getRules.isMaxBySpend(
					this.campaign.budget,
					this.account.currency.warning_budget_limit
				);
				return typeof result === "boolean" ? "" : result;
			}
			return "";
		},*/
	},
	methods: {
		// resetValidation() {
		// 	setTimeout(() => {
		// 		this.$refs.form.resetValidation();
		// 	}, 1000);
		// },

		async getBtnShows() {
			
			let buttons: string[] = [];

			this.showActionButtons = buttons;

			if(!this.isReadOnly) {
				buttons.push("save");
				buttons.push("save-continue");
			};

			if(this.hasLineItems) {
				buttons.push("view-line-item");
			};

			buttons.push("cancel");

			this.showActionButtons = buttons;

			return buttons;
		},

		hasError(index: string | number) {
			return this.errors.hasOwnProperty(index);
		},

		getError(index: any) {
			return getError(this.getErrors, index);
		},

		/**
		 * Optimization Strategy by type
		 */
		isOptimizationStrategyByType(type: String) {
			return this.checkSelectedIDByName(
				this.getOptimizationStrategies,
				type,
				this.campaign.optimization_strategy_id
			);
		},
		// para enviar el continente

		isValidNumber(num: Number) {
			return !isNaN(num) && isNumber(num);
		},

		/**
		 * ID is True O False - not number
		 * @param data 'colection'
		 * @param value 'value to search'
		 * @param id 'comparator'
		 */
		checkPMPSelectedByID(data: any, value: String, id: any){
			const result = data.find(element => element.id == value);
			return result && result.id == id;
		},

		checkSelectedIDByName(data: any, value: String, id: Number) {
			const result = find(data, { value: value });
			return result && result.id === id;
		},

		checkSelectedIDByNames(
			data: Array<any>,
			values: Array<any>,
			id: Number
		) {
			const result = data.filter((d) => values.includes(d.value));
			const hasId = result.some((r) => r.id == id);
			return hasId;
		},

		tooltipData(params: any) {
			return {
				icon: "mdi-information-outline",
				text: params,
				to: "",
				right: params?.right ?? true,
			};
		},

		async validate() {
			let form = this.$refs.form;
			const valid = await form.validate();
			return await valid;
		},
		handleCancel() {
			this.$router.push({ name: "CampaignsIndex" });
		},

		/**
		 * From CardActions - line item
		 * @param action
		 */
		async handleAction(action: { type: any }) {
			switch (action.type) {
				case "save":
					await this.handleSubmit({ continue: false });
					break;

				case "save-continue":
					await this.handleSubmit({ continue: true });
					break;

				case "cancel":
					this.handleCancel();
					break;

				case "viewLine":
					this.$router.push({name: "LineItemList", query: {campaign_id: this.campaign.id}});
					break;
			}
		},

		async handleSubmit(params: { continue: Boolean } = { continue: true }) {
			try {
				this.end_date_rules = [];
				const camp = await this.prepareDataSave();

				if(this.isEdit){
					if(!(await this.verificatedChangeOnlyCampaign(camp))){
						await this.addDailyBudgetValidation();
						await this.addStartDateValidations(true);
						await this.addEdDateValidaitions();
					}
				}else{
					await this.addDailyBudgetValidation();
					await this.addStartDateValidations(true);
					await this.addEdDateValidaitions();
				}

				if (!(await this.validate())) return;

				this.$emit("save", {
					method: this.isEdit ? "update" : "create",
					continue: params.continue,
					data: camp,
				});
			} catch (error) {
				console.error("handleSubmit", { error: error });
			}
		},

		prepareDataSave() {
			return {
				id: this.isEdit ? Number(this.campaign.id) : undefined,
				advertiser_id: Number(this.campaign.advertiser_id),
				name: String(this.campaign.name),
				budget: Number(this.campaign.budget),
				daily_budget: this.campaign.daily_budget ? Number(this.campaign.daily_budget) : undefined,
				budget_type_id: Number(this.campaign.budget_type_id),
				start_date: this.moment(this.campaign.start_date).format(
					DEFAULT_DATE_TIME_FORMAT
				),
				end_date: this.moment(this.campaign.end_date).format(
					DEFAULT_DATE_TIME_FORMAT
				),
				active: this.isEdit ? Number(this.campaign.active) : 1,
				alternative_id: this.campaign.alternative_id,
				optimization_strategy_id: Number(
					this.campaign.optimization_strategy_id
				),
				campaign_pacing_id: Number(this.campaign.campaign_pacing_id),
				kpi_campaign_id: this.campaign.kpi_campaign_id
					? Number(this.campaign.kpi_campaign_id)
					: null,
				kpi_objective: this.campaign.kpi_objective
					? Number(this.campaign.kpi_objective)
					: null,
				trafficker_id: Number(this.campaign.trafficker_id),
				continent: isNull(this.campaign.continent) ? null : String(this.campaign.continent),
				campaign_pmp: this.campaign.campaign_pmp,
				frequency_caps: this.campaign.frequency_caps,
			};
		},

		isValidDates() {
			const startDate = this.moment(this.campaign.start_date);
			const endDate = this.moment(this.campaign.end_date);
			return startDate.isValid() && endDate.isValid();
		},

		addRowFrecuency() {
			if (isUndefined(this.campaign.frequency_caps)) return;
			if (this.campaign.frequency_caps.length >= 3) return;
			this.$emit("init-frequency-caps");
			this.campaign.frequency_caps.push({
				impressions: undefined,
				every_time: undefined,
				unit_time_id: undefined,
				type: this.type_frecuency_caps_selected
			});
		},

		deleteRowFrecuency(index: number) {
			if (this.campaign.frequency_caps.length === 0) return;
			this.campaign.frequency_caps.splice(index, 1);
		},

		getCalculateDuration() {
			if (!this.isValidDates()) return;

			const startDate = this.moment(this.campaign.start_date);
			const endDate = this.moment(this.campaign.end_date);

			let days = this.calculateDuration(startDate, endDate);

			if (days < 0) {
				this.campaign.end_date = "";
				this.campaign_duration = undefined;
				return;
			}

			this.campaign_duration = days;
		},

		calculateDuration(start: any, end: any) {
			if (isUndefined(start) || isUndefined(end)) return;
			if (!(start.isValid() && end.isValid())) return -1;

			const duration = this.moment.duration(end.diff(start));
			return Math.ceil(duration.asDays());
		},

		/**
		 * Returns Start Date Validations.
		 * @param isSubmit On Submit.
		 * @param verify Enable date verification if  is same or later than today.
		 */
		async addStartDateValidations(isSubmit: boolean = false) {
			this.start_date_rules = [];

			if (!isSubmit) return this.start_date_rules;

			this.start_date_rules.push(this.getRules.isRequired);

			if (this.isEdit && this.is_changed_date) {
				this.start_date_rules.push(
					this.getRules.isSameOrAfterToday(this.campaign?.start_date)
				);
			}

			if (!this.isEdit) {
				this.start_date_rules.push(
					this.getRules.isSameOrAfterToday(this.campaign?.start_date)
				);
			}

			return this.start_date_rules;
		},

		async addEdDateValidaitions(){
			this.end_date_rules = [];

			this.end_date_rules.push(this.getRules.isRequired);
			if(this.isEdit){
				this.end_date_rules.push(this.getRules.isAfterCompareAndAfterToday(
												this.campaign.end_date,
												this.campaign.start_date));
			}else{
				this.end_date_rules.push(this.getRules.isAfterCompare(
												this.campaign.end_date,
												this.campaign.start_date
										  ));
			}
		},

		/**
		 * Daily budget validation
		 */
		async addDailyBudgetValidation(isReset: Boolean = false) {
			this.rules.daily_budget = isReset ? [] : this.getRuleDailyBudget;
		},

		async changeDate(date: any) {
			this.is_changed_date = true;
			await this.addStartDateValidations(true);
		},

		async verificatedChangeOnlyCampaign(data: any){
			let keysData = Object.keys(data);
			let propertiesChange = new Array();
			keysData.filter(v => !isNull(this.campaignTemp[v])).forEach((k) => {
				if(this.campaignTemp[k] != data[k]){
					propertiesChange.push(k);
				}
			});
			if(propertiesChange.length > 1 || propertiesChange.length <= 0){
				return false;
			}else{
				if(propertiesChange[0] == "name"){
					return true;
				}else{
					return false;
				}
			}
		},

		setLabel(key: string, value: any) {
			this.$emit("update-label", { key, value });
		},

		async loadFrecuencyType(){
			if (!isEmpty(this.campaign.frequency_caps)) {
				const val = this.campaign.frequency_caps[0];
				if(val.hasOwnProperty("type_id")){
					const f_cap_type = await this.frecuency_types.find((e) => e.extra == val.type_id);
					this.type_frecuency_caps_selected = f_cap_type.id;
				}
			}
		},

		async updateTypesFrecuencyCaps(){
			this.campaign.frequency_caps.forEach(element => {
				element.type = this.type_frecuency_caps_selected
			});
		},
	},
	watch: {
		"campaign.start_date"() {
			this.getCalculateDuration();
		},

		"campaign.end_date"() {
			this.getCalculateDuration();
			this.end_date_rules = [];
		},

		"campaign.budget_type_id"(val) {
			this.setLabel(
				"daily_budget",
				`Daily ${
					this.isBudgetTypeImpressions ? "Impressions" : "Budget"
				}`
			);
		},

		async "campaign.frequency_caps"(val) {
			await this.loadFrecuencyType();
		},

		async "campaign.daily_budget"(val) {
			await this.addDailyBudgetValidation(true);
		},

		async "type_frecuency_caps_selected"(){
			await this.updateTypesFrecuencyCaps();
		},

		async "frecuency_types"(){
			await this.loadFrecuencyType();
		},

		campaign: {
			async handler(val) {
				if(!val.id) return;
				await this.getBtnShows();
			},
			deep: true,
			immediate: true,
		}
	},
});
