
import CustomTab from "./createTabs.vue";
import Vue from "vue";
import { isEmpty, size, isNull } from "lodash";

export default Vue.extend({
	name: "NavModifiersModels",
	props: {
		current_tab: {
			type: Number,
			default: 0,
		},
		entity: {
			type: Object,
			default: function () {
				return {};
			},
		},
		updateCountBidModifier:{
			type: Boolean,
			default: true
		}
	},
	components: {
		CustomTab,
	},
	data: () => ({
		stateCount: 0
	}),
	created() {
		this.$nextTick(async () => {
			this.stateCount = isNull(this.entity.modifier_id) ? 0 : 1;
		})
	},
	mounted() {},
	computed: {
		getItems() {
			return [
				{
					key: 0,
					tab: "Bid Modifier",
					disabled: false,
					//count: this.getCountKeys("bid_modifier"),
					count: this.getSizeBidModifier(),
					src: require(`@/assets/icons/mallet_icon.png`),
				},
				{
					key: 1,
					tab: "Delivery Modifier",
					disabled: false,
					//count: this.getCountKeys("delivery_modifier"),
					count: this.getSizeDeliveryModifier(),
					src: require(`@/assets/icons/delivery_car_icon.png`),
				},
				{
					key: 2,
					tab: "Bid Model",
					disabled: true,
					//count: this.getCountKeys("bid_model"),
					count: 0,
					src: require(`@/assets/icons/currency_icon.png`),
				},
				{
					key: 3,
					tab: "Delivery Model",
					disabled: true,
					//count: this.getCountKeys("delivery_model"),
					count: 0,
					src: require(`@/assets/icons/package_icon.png`),
				}
			];
		},
	},
	methods: {
		updateSelectedTabIndex(index: number) {
			this.$emit("update-selected-tab-index", index);
		},
		getCountKeys(key: any): Number {
			const tk = this.entity[key];

			if ( isEmpty(tk) ) return 0;

			const values: Array<any> = Object.values(tk).filter((t: any) => {
				return !isEmpty(t.terms);
			});

			return size(values);
		},

		getSizeBidModifier(){
			if(this.updateCountBidModifier){
				if(!isNull(this.entity.modifier_id)){
					return 1;
				}else{
					return 0;
				}
			}else{
				return this.stateCount
			}
		},

		getSizeDeliveryModifier(){
			if(this.updateCountBidModifier){
				if(!isNull(this.entity.delivery_modifier_id)){
					return 1;
				}else{
					return 0;
				}
			}else{
				return this.stateCount
			}
		}
	},
});
