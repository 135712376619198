
import CreateTabs from "./createTabs.vue";
import Overview from "./overview.vue";
import Vue from "vue";
// @ts-ignore
import i18n from "@/plugins/i18n";
import { AdvertiserFilters } from "../../../../interfaces/advertiser";
import { initCampaign } from "../../../../utils/initData";
import Alertize from "../../../../components/Alertize.vue";
import { isEmpty, isNull, isUndefined } from "lodash";
import { CampaignDataCreate } from "../../../../interfaces/campaign";
// @ts-ignore
import CardActions from "@/components/Content/CardActions.vue";
// @ts-ignore
import NavModifiersModels from "@/views/Admin/Campaigns/Dependencies/TabNavigation.vue";
// @ts-ignore
import BidModelContent from "@/views/Admin/Campaigns/Dependencies/Contents/bidModel.vue";
// @ts-ignore
import BidModifierContent from "@/views/Admin/Campaigns/Dependencies/Contents/bidModifier.vue";
// @ts-ignore
import DeliveryModelContent from "@/views/Admin/Campaigns/Dependencies/Contents/deliveryModel.vue";
// @ts-ignore
import DeliveryModifierContent from "@/views/Admin/Campaigns/Dependencies/Contents/deliveryModifier.vue";
// @ts-ignore
import { Notification, MessageTypes } from "@/interfaces/proccess";
import { SortingOption } from "../../../../interfaces/paginated";
import { mapActions } from "vuex";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";

export default Vue.extend({
	name: "CreateCampaign",
	props: {},
	components: {
		CreateTabs,
		Overview,
		Alertize,
		CardActions,
		NavModifiersModels,
		BidModelContent,
		BidModifierContent,
		DeliveryModelContent,
		DeliveryModifierContent,
	},
	data: () => ({
		title: "Create",
		currentTab: 0,
		items: [],
		account: {
			currency: { name: "USD" },
			timezone: { name: "UTC" },
		},
		campaign: initCampaign(),
		updateCountBidModifier: true,

		// Tab Modifiers and Models
		currentTabModifiers: 0,

		success: {
			type: MessageTypes.SUCCESS,
			title: i18n.t("title-success"),
			message: i18n.t("success"),
			btn_text: i18n.t("continue"),
			show: true,
			to: undefined,
			btn_text_redirect: i18n.t('create_new_line'),
    		to_redirect: "LineItemCreate",
    		show_redirect: false,
			query: undefined,
			params: undefined
		} as Notification,

		fieldLabel: {
			daily_budget: 'Daily Budget'
		},

		resources:{
            continents:[],
			types_frecuency_caps:[]
        },
	}),
	created() {},
	async mounted() {
		await this.dispatchAccount();
		await this.dispatchBudgetTypes();
		await this.dispatchAdvertisers();
		await this.dispatchKpiCampaigns();
		await this.dispatchTraffickers();
		await this.dispatchOptimizationStrategies();
		await this.dispatchCampaignPacing();
		await this.dispatchContinents();
		await this.dispatchTypesFrecuencyCaps();
	},
	computed: {
		getAccount() {
			return this.$store.state.account.account;
		},
		getBudgetTypes() {
			return this.$store.state.custom_list.budget_types;
		},
		getAdvertisers() {
			return this.$store.state.advertiser.advertisers_list;
		},
		getCampaignsPacing() {
			return this.$store.state.custom_list.campaigns_pacing;
		},
		getOptimizationStrategies() {
			return this.$store.state.custom_list.optimization_strategies;
		},
		getKpiCampaigns() {
			return this.$store.state.custom_list.kpi_campaigns;
		},
		getUnitTimes() {
			return this.$store.state.custom_list.unit_times;
		},
		getTraffickers() {
			return this.$store.state.user.traffickers;
		},
		getContinens(){
			return this.resources.continents;
		},
		getFrecuencyTypes(){
			return this.resources.types_frecuency_caps;
		},
		getErrors() {
			return this.$store.state.proccess.errors;
		},
		hasErrors() {
			return Object.keys(this.$store.state.proccess.errors).length > 0;
		},
		getItems() {
			this.items = [
				{
					key: 0,
					label: "Overview",
					disabled: false,
				},
				{
					key: 1,
					label: "Modifiers / Models",
					disabled: !this.isCreatedEntity,
				},
			];
			return this.items;
		},
		isCreatedEntity() {
			return this.hasData(this.getCampaign.id);
		},
		getCampaign() {
			return this.$store.state.campaign.campaign;
		},

		getModifier() {
			return this.$store.state.modifier.modifier;
		},
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),

		...mapActions("campaign", [
			"continentsList",
		]),

		handleCancelModifier() {
			this.$router.push({ name: "CampaignsIndex" });
		},

		redirectTo() {
			this.setNotification({ title: "", message: "", type: "" });
			this.$router.push({ name: "CampaignsList" });
		},

		setNotification(notification: Notification) {
			return this.$store.dispatch("proccess/setNotification", notification, {
				root: true,
			});
		},

		updateSelectedTabIndex(index: number) {
			this.currentTab = index;
		},

		currentTabSelected(item: String, selected: String) {
			return item === selected;
		},

		setCampaign(model: any) {
			this.campaign = model;
		},

		/**
		 * GET
		 */
		async dispatchContinents(){
            const response = await this.continentsList();
            Object.entries(response).forEach(([key, valor]) => {
                let obj = {
                    id: valor,
                    value: key
                }
                this.resources.continents.push(obj);
            });
        },

		async dispatchAdvertisers() {
			return this.$store.dispatch("advertiser/list", {
				filters: { active: true } as AdvertiserFilters,
				options: { sort: "name", order: "asc" } as SortingOption,
			});
		},

		async dispatchAccount() {
			return this.$store.dispatch("account/getAccount");
		},

		async dispatchBudgetTypes() {
			return this.$store.dispatch("custom_list/getBudgetTypes");
		},

		async dispatchCampaignPacing() {
			return this.$store.dispatch("custom_list/getCampaignPacing");
		},

		async dispatchOptimizationStrategies() {
			return this.$store.dispatch("custom_list/getOptimizationStrategies");
		},

		async dispatchKpiCampaigns() {
			return this.$store.dispatch("custom_list/getKpiCampaigns");
		},

		async dispatchUnitTimes() {
			if (!isEmpty(this.getUnitTimes)) return;
			return this.$store.dispatch("custom_list/getUnitTimes");
		},

		async dispatchTraffickers() {
			return this.$store.dispatch(
				"user/traffickers",
				{ filters: { active: true }, options: {} },
				{ root: true }
			);
		},

		async dispatchTypesFrecuencyCaps() {
			if (!isEmpty(this.resources.types_frecuency_caps))
				return this.resources.types_frecuency_caps;
			this.$store.dispatch("custom_list/getTypesFrecuencyCaps")
			.then((res) =>{
				this.resources.types_frecuency_caps = res;
			})
			.catch((err) => {
				this.resources.types_frecuency_caps = [];
			});
		},

		async handleSubmit(params: {
			method: string;
			continue: Boolean;
			data: any;
		}) {
			try {
				await this.setLoadingData(TypeLoading.loading);
				if (!params.continue) {
					const result = await this.create(params);
					this.success.show = true;
					this.success.to = "CampaignsIndex";
					this.success.show_redirect = true;
					this.success.query = {campaign_id: result.id};
					this.setNotification(this.success);
				} else {
					const result = await this.create(params);
					if (!isUndefined(result) && !isNull(result) && !isEmpty(result)) {
						this.success.to = undefined;
						this.success.show = true;
						this.updateSelectedTabIndex(1);
					}
				}
				await this.setLoadingData();
			} catch (error) {
				//console.error("handleSubmit", { error: error });
				await this.setLoadingData();
			}
		},

		async create(params: {
			method: string;
			continue: Boolean;
			data: CampaignDataCreate;
		}) {
			return this.$store.dispatch("campaign/create", params);
		},

		async CreateModifier(parameter: { entity: any; isBidModifier: Boolean }) {
			try {
				//await this.setLoadingData(TypeLoading.loading);
				//await this.dispatchCreateEntity(parameter.entity, parameter.isBidModifier);
			} catch (error) {
				console.error("Handle create modifier from campaigns", {
					error: error,
				});
			}
			await this.setLoadingData();
		},

		/**
		 * From CardActions - Modifiers/Models
		 * @param action
		 */

		async handleSubmitModifier(params) {
			try {
				await this.setLoadingData(TypeLoading.loading);
				const result = await this.dispatchCreateModifier(
					params.entity,
					params.isBidModifier
				);
				this.getCampaign.modifier_id = result.id;
				this.updateCountBidModifier = false;
				if (isUndefined(result) || isNull(result) || isEmpty(result)) {
					this.setNotification({
						title: this.$t("title-failed"),
						message: this.$t("failed"),
						btn_text: this.$t("try-again"),
						type: MessageTypes.ERROR,
						show: true,
					});
				} else {
					this.setNotification({
						title: this.$t("title-success"),
						message: this.$t("success"),
						btn_text: this.$t("continue"),
						type: MessageTypes.SUCCESS,
						show: true,
					});
					await params.executeDispatchFunction();
					await params.executeClearFunction();
				}
			} catch (error) {
				console.error("handleSubmitModifier", { error: error });
			}
			await this.setLoadingData();
		},

		async handleCreateAssociation(params) {
			try {
				
				await this.setLoadingData(TypeLoading.loading);
				const result = await this.dispatchCreateAssociation(
					params.module,
					params.entity
				);
				if (!isUndefined(result) && !isNull(result) && !isEmpty(result)) {
					if (params.continue) {
						this.success.to = undefined;
						this.setNotification(this.success);
						//this.$router.push({ name: "LineItemCreate" });
						this.$router.push({
							name: "LineItemCreate",
							query: { campaign_id: params.entity.campaign_id },
						});
					} else {
						this.success.to = "CampaignsIndex";
						this.setNotification(this.success);
						//this.$router.push({ name: "CampaignsList" });
					}
				}
			} catch (error) {
				console.error("handleCreateAssociation", { error: error });
			}
			await this.setLoadingData();
		},

		async handleClear(key: any) {
			switch (key) {
				case "clear-relations":
					this.line_item.start_date = null;
					this.line_item.end_date = null;
					this.line_item.line_duration = null;
					this.resources.selected_campaign = {
						advertiser: { id: null, name: null },
					};

					// advertiser_id
					this.setField("advertiser_id", {
						show: true,
						required: true,
						disabled: true,
						value: null,
					});

					// budget_type_id
					this.setField("budget_type_id", {
						show: true,
						required: true,
						disabled: false,
						value: null,
					});

					// budget
					this.setField("budget", {
						show: true,
						required: true,
						disabled: false,
						value: null,
					});

					// line_pacing_id
					this.setField("line_pacing_id", {
						show: false,
						required: false,
						disabled: true,
						value: null,
					});

					// strategy_id
					this.setField("strategy_id", {
						show: true,
						required: false,
						disabled: true,
						value: null,
					});

					// bid_strategy_id
					this.setField("bid_strategy_id", {
						show: true,
						required: true,
						disabled: true,
						value: null,
					});

					// daily_budget
					// this.setField("daily_budget", {
					// 	show: false,
					// 	required: false,
					// 	disabled: true,
					// 	value: null
					// });

					// cpm_bid
					// this.setField("cpm_bid", {
					// 	show: false,
					// 	required: false,
					// 	disabled: true,
					// 	value: null
					// });

					await this.updateBudgetDisplay();

					break;

				default:
					break;
			}
		},

		handleLabel(event: { key: string; value: any; }) {
			this.fieldLabel[event.key] = event.value;
		},

		updateSelectedTabModifiersIndex(index: number) {
			this.currentTabModifiers = index;
		},

		hasData(entity: any) {
			return !isUndefined(entity) && !isNull(entity);
		},

		async dispatchCreateAssociation(module: string, entity: any) {
			let result: any = undefined;
			switch (module) {
				case "BID_MODIFIER":
					result = await this.dispatchCreateAssociationWithBidModifier(entity);
					break;
				case "DELIVERY_MODIFIERS":
					result = await this.dispatchCreateAssociationWithBidModifier(entity);
					break;
			}
			return result;
		},

		async dispatchCreateAssociationWithBidModifier(entity: any) {
			return await this.$store.dispatch(
				"campaign/createAssociationWithModifier",
				{ entity: entity },
				{ root: true }
			);
		},

		async dispatchCreateModifier(entity: any, isBidModifier: Boolean = false) {
			return this.$store.dispatch(
				"modifier/createModifier",
				{
					modifier: entity,
					isBidModifier: isBidModifier,
				},
				{
					root: true,
				}
			);
		},

		handleCancel() {
			this.$router.push({ name: "CampaignsList" });
		},
	},

	watch: {},
});
